<template>
  <div>
    <v-btn color="error" small @click="closeItem" outlined> Cerrar</v-btn>
    <v-btn
      type="submit"
      :form="formId"
      color="success"
      class="px-6 mx-4"
      :loading="submitLoading"
      small
      outlined
      @click="handleCloseAfterSubmit(false)"
    >
      Guardar
    </v-btn>
    <v-btn
      type="submit"
      :form="formId"
      color="success"
      class="px-6"
      :loading="submitLoading"
      small
      depressed
      @click="handleCloseAfterSubmit(true)"
    >
      Guardar y cerrar
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    submitLoading: { type: Boolean, default: false },
    formId: { type: String, required: true },
  },
  methods: {
    handleCloseAfterSubmit(value) {
      this.$emit("handleCloseAfterSubmit", value);
    },
    closeItem() {
      this.$emit("closeItem");
    },
  },
};
</script>

<style scoped></style>
